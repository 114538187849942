import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import SEO from '../components/YoastSEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(110)};
  text-align: center;
`;

function NotFound() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Hero heading="404" pageTitle />
      <Container>
        <Row>
          <Col>
            <h2>Page not found</h2>
            <p>Oops, we couldn&#39;t find this page!</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default NotFound;
